<script>
import { mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { useAlert } from 'dashboard/composables';
import { required } from '@vuelidate/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader.vue';
import ZaloAPI from 'dashboard/api/zalo';
import { ZALO_CALLBACK } from 'dashboard/routes/dashboard/conversation/constants';

export default {
  components: {
    PageHeader,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      botToken: '',
      popupWindow: null,
      redirectUri: encodeURIComponent(`${process.env.FRONTEND_URL}${this.$route.path}`)
    }
  },
  mounted() {
    this.handleZaloCallback()
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      currentUser: 'getCurrentUser'
    }),
    showLogin() {
      return !this.$route.query.code || !this.$route.query.oa_id;
    }
  },
  validations: {
    botToken: { required },
  },
  methods: {
    async handleZaloCallback() {
      const code = this.$route.query.code;
      const oa_id = this.$route.query.oa_id;
      if (!code || !oa_id) return;
      try {
        const res = await ZaloAPI.loginCallback({
          code,
          oa_id,
          account_id: this.currentUser.account_id,
          chatwoot_access_token: this.currentUser.access_token,
          create: 'true'
        })

        if (res.data.error) {
          useAlert(res.data.message);
          return;
        }

        const zaloChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: res.data.name,
            channel: {
              type: "api",
              webhook_url: ZALO_CALLBACK
            }
          }
        );

        await ZaloAPI.updateZaloOa(res.data.id, {
          inbox_id: zaloChannel.id
        })

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: zaloChannel.id,
          },
        });
      } catch (err) {
        console.log(err);
      }
    }
  },
};
</script>

<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0">
    <PageHeader header-title="Zalo Channel"
      header-content="Integrate with Zalo channel and start supporting your customers." />
    <div>
      <a
        v-if="showLogin"
        :href="`https://oauth.zaloapp.com/v4/oa/permission?app_id=2516076082168337372&redirect_uri=${redirectUri}`"
      >
        Đăng nhập Zalo để tiếp tục
      </a>
    </div>
  </div>
</template>
