<script>
import ContactAPI from '../../../../api/contacts';
import { useVuelidate } from '@vuelidate/core';
import { required, url } from '@vuelidate/validators';
import { useAccount } from 'dashboard/composables/useAccount';

const LIST_TYPE_BOT = [
  { value: 'callback', label: 'Callback' },
  { value: 'response', label: 'Response' }
];

export default {
  data() {
    return {
      v$: useVuelidate(),
      LIST_TYPE_BOT,
      id: '',
      botName: '',
      botUrl: '',
      password: '',
      botType: LIST_TYPE_BOT[0].value,
    };
  },
  components: {
  },
  setup() {
    const { accountId } = useAccount();
    return { accountId };
  },
  validations() {
    return {
      botName: { required },
      botUrl: { required, url },
      botType: { required },
      password: {}
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cancel', 'update:show', 'updateAddbot'],
  computed: {
    localShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    msgErrorUrlBot() {
      if (this.v$.botUrl.$error) {
        return 'Đường dẫn api không hợp lệ'
      }
      return ''
    }
  },
  watch: {
  },
  mounted() {
    console.log(this.initData)
  },
  methods: {
    setValue(bot) {
      this.id = bot?.id || '';
      this.botName = bot?.name || '';
      this.botUrl = bot?.path || '';
      this.botType = bot?.botType || LIST_TYPE_BOT[0].value;
      this.password = bot?.password || '';
    },
    async confirmAddBot() {
      const dataReq = {
        name: this.botName,
        path: this.botUrl,
        password: this.password,
        botType: this.botType,
        accountId: this.accountId,
      }
      if (!this.id) {
        const res = await ContactAPI.createBot(dataReq)
        if (res && res.data) {
          this.$emit('updateAddbot', res.data.data);
        }
        return
      }
      const res = await ContactAPI.updateBot(this.id, dataReq)
      if (res && res.data) {
        this.$emit('updateAddbot', res.data.data);
      }
      return
    },
    onCancel() {
      this.$emit('cancel');
    }
  },
};
</script>

<template>
  <woot-modal v-model:show="localShow" :on-close="onCancel">
    <div class="flex flex-col h-auto overflow-auto">
      <div class="flex flex-col items-start px-8 pt-8 pb-0">
        <h2 data-test-id="modal-header-title" class="text-base font-semibold leading-6 text-slate-800 dark:text-slate-50">Thêm bot tích hợp</h2>
      </div>
      <form class="flex flex-wrap mx-0" @submit.prevent="confirmAddBot">
        <woot-input
          v-model="botName"
          :class="{ error: v$.botName.$error }"
          class="w-full label-name--input"
          label="Tên bot"
          placeholder="Nhập tên bot"
          :error="v$.botName.$error? 'Tên bot không được bỏ trống': ''"
          data-testid="bot-name"
          @input="v$.botName.$touch"
          @blur="v$.botName.$touch"
        />
        <woot-input
          v-model="botUrl"
          :class="{ error: v$.botUrl.$error }"
          class="w-full label-name--input"
          label="Đường dẫn api"
          placeholder="Nhập Url api"
          :error="msgErrorUrlBot"
          data-testid="bot-url"
          @input="v$.botUrl.$touch"
          @blur="v$.botUrl.$touch"
        />
        <woot-input
          v-model="password"
          :class="{ error: v$.botUrl.$error }"
          class="w-full label-name--input"
          label="Mật khẩu"
          placeholder="Nhập mật khẩu"
          data-testid="bot-password"
          @input="v$.password.$touch"
          @blur="v$.password.$touch"
        />
        <label class="w-full">
          Loại bot
          <select v-model="botType">
            <option v-for="type in LIST_TYPE_BOT" :key="type.value" :value="type.value">
              {{ type.label }}
            </option>
          </select>
        </label>
        <div  class="flex flex-row justify-end w-full gap-2 px-0 py-2">
          <button class="button clear" @click.prevent="onCancel">
            {{ $t('NEW_CONVERSATION.FORM.CANCEL') }}
          </button>
          <woot-button
            :is-disabled="v$.botName.$invalid || v$.botUrl.$invalid"
            data-testid="label-submit"
          >
            Tạo bot
          </woot-button>
        </div>
      </form>
    </div>
  </woot-modal>
</template>
