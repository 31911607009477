<script>
import FileUpload from 'vue-upload-component';
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview.vue';
import { mapGetters } from 'vuex';
import TrainingBotAPI from 'dashboard/api/trainingBot';
import { useAccount } from 'dashboard/composables/useAccount';
import { useAlert } from 'dashboard/composables';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import { emitter } from 'shared/helpers/mitt';

const ACCEPT_TYPE = "application/pdf"

export default {
  name: 'FilesTrainingBot',
  components: {
    FileUpload,
    AttachmentPreview
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    collectionName: {
      type: String,
      required: true
    }
  },
  // mixins: [fileUploadMixin],
  computed: {
    ...mapGetters({ globalConfig: 'globalConfig/get' }),
    hasAttachments() {
      return this.attachedFiles.length;
    },
    fileName() {
      return (file) => file.filename || file.name;
    },
    inboxId() {
      return this.$route.params.inboxId;
    },
  },
  setup() {
    const { accountId } = useAccount();

    return {
      accountId,
    }
  },
  data() {
    return {
      attachedFiles: [],
      acceptType: ACCEPT_TYPE,
      trainedFiles: [],
    }
  },
  mounted() {
    this.getTrainedFiles();
    emitter.on(BUS_EVENTS.TRAIN_BOT, this.onSubmitTrain);
  },
  unmounted() {
    emitter.off(BUS_EVENTS.TRAIN_BOT, this.onSubmitTrain);
  },
  methods: {
    getTrainedFiles() {
      TrainingBotAPI.getTrainedFiles(this.accountId, this.inboxId)
        .then(response => {
          this.trainedFiles = response.data?.filter(file => file.result)
        })
        .catch(err => {
          useAlert('Get trained files error');
          console.log(err);
        })
    },
    handleClickMenu(menuItem) {
      this.selectedMenuKey = menuItem.key
      this.selectedMenuName = menuItem.name
    },
    // onPaste(e) {
    //   const data = e.clipboardData.files;
    //   if (!data.length || !data[0]) {
    //     return;
    //   }
    //   data.forEach(file => {
    //     const { name, type, size } = file;
    //     this.onFileUpload({ name, type, size, file: file });
    //   });
    // },
    removeAttachment(index) {
      this.attachedFiles.splice(index, 1);
    },
    onFileUpload(file) {
      this.attachedFiles.push({...file});
    },
    // updateTrainingInfo(name) {
    //   TrainingBotAPI.getTrainedFiles(this.inboxId, name)
    //     .then(response => {
    //       this.trainedFiles = response.data?.filter(file => file.result)
    //     })
    //     .catch(err => {
    //       useAlert('Get trained files error');
    //       console.log(err);
    //     })
    // },
    onDeleteTrainedFile(id) {
      TrainingBotAPI.deleteTrainedFile(id)
        .then(() => {
          this.trainedFiles = this.trainedFiles.filter(file => file.id !== id)
        })
        .catch(err => {
          useAlert(`Error deleting ${this.fileName(file)}`)
        })
    },
    onSubmitTrain() {
      Promise.all(this.attachedFiles.map((file) => {
        const formData = new FormData();
        formData.append('file', file.file);
        formData.append('collection_name', this.collectionName);
        formData.append('account_id', this.accountId);
        formData.append('loader_type', 'pdf');
        formData.append('inbox_id', this.inboxId);
        return TrainingBotAPI.uploadTrainedFiles(formData)
          .then(response => {
            this.trainedFiles.push(response.data)
            this.attachedFiles = this.attachedFiles.filter(f => f.id !== file.id)
          })
          .catch(err => {
            useAlert(`Error uploading ${this.fileName(file)}`)
          })
      }))
        .finally(() => {
          this.$emit('train-done')
      })
    }
  }
}
</script>
<template>
  <div>
    <FileUpload
      ref="uploadRef"
      input-id="trainingFiles"
      :multiple="true"
      :drop="true"
      :drop-directory="false"
      :data="{
        direct_upload_url: '/rails/active_storage/direct_uploads',
        direct_upload: true,
      }"
      :accept="acceptType"
      @input-file="onFileUpload"
      class="custom-file-upload"
    >
      <div class="flex flex-col flex-1 p-6 bg-white border border-solid rounded-md dark:bg-slate-800 border-slate-50 dark:border-slate-700/50">
        <div class="text-base">Drag & drop files here, or click to select files</div>
        <div class="text-sm">Supported File Types: .pdf</div>
      </div>
    </FileUpload>
    <div
      class="attachment-preview-box-2 pt-4"
    >
      <div
        v-for="(file, index) in attachedFiles"
        :key="index"
        class="preview-item flex items-center p-1 bg-slate-50 dark:bg-slate-800 gap-1 rounded-md w-[15rem] mb-1 transition-all"
        :class="{'opacity-50': loading}"
      >
        <div class="max-w-[4rem] flex-shrink-0 w-6 flex items-center">
          <span class="relative w-6 h-6 text-lg text-left -top-px">
            📄
          </span>
        </div>
        <div class="min-w-[60%] overflow-hidden text-ellipsis">
          <span
            class="h-4 overflow-hidden text-sm font-medium text-ellipsis whitespace-nowrap"
          >
            {{ fileName(file) }}
          </span>
        </div>
        <div class="w-[30%] justify-center">
          <span class="overflow-hidden text-xs text-ellipsis whitespace-nowrap">
            {{ 12323 }}
          </span>
        </div>
        <div class="flex items-center justify-center">
          <woot-button
            class="!w-6 !h-6 text-sm rounded-md hover:bg-slate-50 dark:hover:bg-slate-800 clear secondary"
            icon="dismiss"
            :disabled="loading"
            @click="removeAttachment(index)"
          />
        </div>
      </div>
    </div>
    <div
      class="attachment-preview-box-2 pt-4"
    >
      <div class="pb-2 text-sm">Trained File</div>
      <div
        v-for="file in trainedFiles"
        :key="file.id"
        class="preview-item flex items-center p-1 bg-slate-50 dark:bg-slate-800 gap-1 rounded-md w-[15rem] mb-1"
      >
        <div class="max-w-[4rem] flex-shrink-0 w-6 flex items-center">
          <span class="relative w-6 h-6 text-lg text-left -top-px">
            📄
          </span>
        </div>
        <div class="min-w-[60%] overflow-hidden text-ellipsis">
          <span
            class="h-4 overflow-hidden text-sm font-medium text-ellipsis whitespace-nowrap"
            :class="{'text-red-800 dark:text-red-400': !file.result}"
          >
            {{ fileName(file) }}
          </span>
        </div>
        <div class="w-[30%] justify-center">
          <span class="overflow-hidden text-xs text-ellipsis whitespace-nowrap">
            {{ 12323 }}
          </span>
        </div>
        <div class="flex items-center justify-center w-6">
          <woot-button
            class="!w-6 !h-6 text-sm rounded-md clear"
            icon="delete"
            color-scheme="alert"
            v-if="file.result"
            @click="onDeleteTrainedFile(file.id)"
          />
        </div>
      </div>
      <div v-if="!trainedFiles.length" class="italic text-xs">Empty</div>
    </div>
  </div>
</template>

<style lang="scss">
.custom-file-upload {
  width: 100%;
}

.attachment-preview-box-2 {
  .preview-item {
    width: 100%;
    justify-content: space-between;
  }
}
</style>
