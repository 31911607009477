import { instanceIntegration } from '../api/instance/instanceIntegration';

class FacebookAPI {
  login(params) {
    return instanceIntegration.get('/facebook/connect', { params });
  }
  getListFbPage(params) {
    return instanceIntegration.get('/facebook/pages', { params });
  }
  createInbox(body) {
    return instanceIntegration.post('/facebook/inbox', body);
  }
  deleteInbox(account_id, inbox_id) {
    return instanceIntegration.delete(`/facebook?account_id=${account_id}&inbox_id=${inbox_id}`)
  }
}

export default new FacebookAPI();
