<script>
import InboxReconnectionRequired from '../../components/InboxReconnectionRequired.vue';
import { useI18n } from 'vue-i18n';
import { useAlert } from 'dashboard/composables';
import ZaloAPI from 'dashboard/api/zalo';
import { mapGetters } from 'vuex';

export default {
  components: {
    InboxReconnectionRequired,
  },
  props: {
    inbox: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    return {
      redirectUri: encodeURIComponent(`${process.env.FRONTEND_URL}${this.$route.path}`),
      zaloInbox: {}
    }
  },
  mounted() {
    console.log(this.inbox)
    this.handleZaloCallback()
    this.getZaloInbox()
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      currentUser: 'getCurrentUser'
    }),
    isAuthorize() {
      return !this.$route.query.code || !this.$route.query.oa_id;
    },
    showAuthorize() {
      return this.zaloInbox.id && (
        this.zaloInbox.is_expired ||
        new Date().getTime() > new Date(this.zaloInbox.updated_at).getTime() + 3*30*24*3600*1000
      )
    },
    currentInboxId() {
      return this.$route.params.inboxId;
    },
  },
  methods: {
    async handleZaloCallback() {
      const code = this.$route.query.code;
      const oa_id = this.$route.query.oa_id;
      if (!code || !oa_id) return;
      try {
        const res = await ZaloAPI.loginCallback({
          code,
          oa_id,
          account_id: this.currentUser.account_id,
          chatwoot_access_token: this.currentUser.access_token,
          inbox_id: this.currentInboxId
        })
        if (res.data.error) {
          useAlert(res.data.message);
          return;
        }
        this.zaloInbox.is_expired = false;
        this.zaloInbox.updated_at = new Date();
      } catch (err) {
        useAlert(this.$t('INBOX_MGMT.ADD.MICROSOFT.ERROR_MESSAGE'));
        console.log(err);
      }
    },
    async getZaloInbox() {
      const res = await ZaloAPI.getZaloInbox(this.currentUser.account_id, this.currentInboxId)
      if (res.data) {
        this.zaloInbox = res.data
      }
    },
    async requestAuthorization() {
      window.location.href=`https://oauth.zaloapp.com/v4/oa/permission?app_id=2516076082168337372&redirect_uri=${this.redirectUri}`
    },
  },
};
</script>

<template>
  <InboxReconnectionRequired
    v-if="showAuthorize"
    class="mx-8 mt-5"
    @reauthorize="requestAuthorization"
  />
</template>
