

<script>
import InfoTraining from './InfoTraining.vue';
import FilesTrainingBot from './FilesTrainingBot.vue';
import QnaTrainingBot from './QnaTrainingBot.vue';
import TextTrainingBot from './TextTrainingBot.vue';
import WebsiteTrainingBot from './WebsiteTrainingBot.vue';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import { emitter } from 'shared/helpers/mitt';
import SettingsLayout from '../../SettingsLayout.vue';
import BaseSettingsHeader from '../../components/BaseSettingsHeader.vue';

const TRAINING_MENU = [
  { key: 'info', name: 'Info', icon: 'info' },
  { key: 'files', name: 'Files', icon: 'document' },
  { key: 'text', name: 'Text', icon: 'text-align-left'},
  { key: 'website', name: 'Website', icon: 'globe'},
  { key: 'qna', name: 'Q&A', icon: 'chat-multiple'},
]
export default {
  name: 'TrainingBot',
  components: {
    InfoTraining,
    FilesTrainingBot,
    TextTrainingBot,
    WebsiteTrainingBot,
    QnaTrainingBot,
    SettingsLayout,
    BaseSettingsHeader
  },
  mixins: [],
  props: {
    inbox: {
      type: Object,
      default: () =>({})
    }
  },
  computed: {
  },
  data() {
    return {
      menu: TRAINING_MENU,
      selectedMenuKey: TRAINING_MENU[1].key,
      selectedMenuName: TRAINING_MENU[1].name,
      loading: false,
      collectionName: 'ecouni_fusion-qa_jina'
    }
  },
  mounted() {
  },
  methods: {
    handleClickMenu(menuItem) {
      this.selectedMenuKey = menuItem.key
      this.selectedMenuName = menuItem.name
    },
    onTrainBot() {
      this.loading = true;
      emitter.emit(BUS_EVENTS.TRAIN_BOT);
    },
    onTrainDone() {
      this.loading = false;
    }
  }
}
</script>

<template>
  <SettingsLayout>
    <template #header>
      <BaseSettingsHeader title="Training Bot" feature-name="Training Bot" description="">
      </BaseSettingsHeader>
    </template>
    <template #body>
      <div class="flex justify-between gap-8">
        <transition-group
          name="menu-list"
          tag="ul"
          class="mb-0 ml-0 list-none"
        >
          <div
            v-for="menuItem in menu"
            class="flex items-center p-2 m-0 text-sm leading-4 rounded-lg cursor-pointer mb-2 w-48"
            :class="{
              'bg-woot-75 dark:bg-woot-200 text-woot-600 dark:text-woot-600': menuItem.key === selectedMenuKey,
              'text-slate-700 dark:text-slate-100 hover:bg-slate-25 dark:hover:bg-slate-800': menuItem.key !== selectedMenuKey,
            }"
            :key="menuItem.key"
            @click="handleClickMenu(menuItem)"
          >
            <!-- <fluent-icon
              :icon="menuItem.icon"
              class="min-w-[1rem] mr-1.5 rtl:mr-0 rtl:ml-1.5"
              size="14"
            /> -->
            {{ menuItem.name }}
          </div>

        </transition-group>
        <div class="flex-1">
          <div class="text-base text-woot-500 dark:text-woot-500 font-medium mb-4">{{ selectedMenuName }}</div>
          <InfoTraining v-model:collection-name="collectionName" v-if="selectedMenuKey === 'info'"/>
          <FilesTrainingBot
            v-if="selectedMenuKey === 'files'"
            :loading="loading"
            @train-done="onTrainDone"
            :collection-name="collectionName"
          />
          <TextTrainingBot v-if="selectedMenuKey === 'text'" />
          <WebsiteTrainingBot v-if="selectedMenuKey === 'website'" />
          <QnaTrainingBot v-if="selectedMenuKey === 'qna'" />
        </div>
        <div class="w-64 min-w-64" v-if="selectedMenuKey !== 'info'">
          <p class="text-base text-woot-500 dark:text-woot-500 mb-4 font-medium">Sources</p>
          <div class="mb-4">
            <div class="text-sm font-medium">Total detected characters</div>
            <div class="text-sm text-center"><span class="font-medium">919</span>/400.000 limit</div>
          </div>
          <woot-button @click="onTrainBot" :disabled="loading">
            {{ loading ? 'Training' : 'Retrain Chatbot'}}
          </woot-button>
        </div>
    </div>
    </template>
  </SettingsLayout>
</template>
