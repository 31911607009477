import { instanceIntegration } from '../api/instance/instanceIntegration';

class TrainingBotAPI {
  async getTrainedFiles(account_id, inbox_id) {
    return instanceIntegration.get('/training-data', { params: { account_id, inbox_id } });
  }
  async uploadTrainedFiles(body) {
    return instanceIntegration.post('/training-data', body);
  }
  async updateTrainingInfo(id, collection_name) {
    return instanceIntegration.put(`/training-data/bots/${id}`, {
      data: { collection_name }
    });
  }
  async deleteTrainedFile(id) {
    return instanceIntegration.delete(`/training-data/${id}`);
  }
}

export default new TrainingBotAPI()
