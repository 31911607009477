<script>
export default {
  name: 'InfoTraining',
  props: {
    collectionName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      inputCollectionName: this.collectionName,
    }
  },
  computed: {
    disabledUpdate() {
      return !this.inputCollectionName || this.inputCollectionName === this.collectionName;
    }
  },
  methods: {
    onUpdateName() {
      this.$emit('update:collection-name', this.inputCollectionName);
    }
  }
}
</script>

<template>
  <div>
    <woot-input
      v-model="inputCollectionName"
      class="w-full label-name--input"
      label="Collection Name"
      data-testid="bot-name"
    />
    <woot-button
      @click="onUpdateName"
      :disabled="disabledUpdate"
    >
      Update Collection Name
    </woot-button>
  </div>
</template>
