<script>
import ContactAPI from '../../../../api/contacts';
import PopupAddBot from './PopupAddBot.vue';
import { useAccount } from 'dashboard/composables/useAccount';

export default {
  data() {
    return {
      selectBotId: '',
      listBot: [],
      showAddBotModal: false
    };
  },
  setup() {
    const { accountId } = useAccount();
    return {
      accountId,
    };
  },
  components: {
    PopupAddBot
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    integrated: {
      type: Boolean,
      default: false,
    },
    integrationInfo: {
      type: Object,
      default: {}
    }
  },
  emits: ['cancel', 'update:show'],
  computed: {
    localShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
  },
  watch: {
    integrationInfo(value) {
      this.selectBotId = value?.bot_id
    }
  },
  mounted() {
    this.getIntegrationInfo()
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('cancel');
    },
    async getIntegrationInfo() {
      const response = await ContactAPI.getListBot(this.accountId)
      this.listBot = response.data
    },
    async updateIntegration() {
      this.$emit('updateIntegration', this.selectBotId);
    },
    deleteIntegration() {
      this.$emit('deleteIntegration');
    },
    showPopupAddBot() {
      this.showAddBotModal = true
    },
    toggleCancelModal() {
      this.showAddBotModal = false
    },
    async updateAddbot(newBot) {
      await this.getIntegrationInfo()
      this.$nextTick(() => {
        this.selectBotId = newBot.id
      });
      this.showAddBotModal = false
    }
  },
};
</script>

<template>
  <div>
    <woot-modal v-model:show="localShow" :on-close="onCancel">
      <div class="flex flex-col h-[250px] overflow-auto">
        <div class="flex flex-col items-start px-8 pt-8 pb-0">
          <div class="flex gap-2">
            <h2 data-test-id="modal-header-title" class="text-base font-semibold leading-6 text-slate-800 dark:text-slate-50">Tích hợp chat bot</h2>
            <woot-button
              v-tooltip.top="'Thêm bot'"
              variant="smooth"
              size="tiny"
              color-scheme="secondary"
              class-names="grey-btn"
              icon="add"
              @click="showPopupAddBot"
            />
          </div>
          <p data-test-id="modal-header-content" class="w-full mt-2 text-sm leading-5 break-words text-slate-600 dark:text-slate-300">Chọn bot để tích hợp </p>
        </div>
        <div class="p-3">
          <select v-model="selectBotId">
            <option v-for="bot in listBot" :key="bot.id" :value="bot.id">
              {{ bot.name }}
            </option>
          </select>
          <div  class="flex flex-row justify-end w-full gap-2 px-0 py-2">
            <button class="button clear" @click.prevent="onCancel">
              {{ $t('NEW_CONVERSATION.FORM.CANCEL') }}
            </button>
            <woot-button  @click.prevent="updateIntegration">
              Tích hợp
            </woot-button>
            <woot-button
              v-if="integrated"
              variant="smooth"
              color-scheme="alert"
              @click="deleteIntegration"
            >
              Bỏ tích hợp
            </woot-button>
          </div>
        </div>
      </div>
    </woot-modal>
    <PopupAddBot
      :show="showAddBotModal"
      @cancel="toggleCancelModal"
      @updateAddbot="updateAddbot"
    />
  </div>
</template>
