<script>
import { useAlert } from 'dashboard/composables';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import { useAdmin } from 'dashboard/composables/useAdmin';
import SettingsLayout from '../SettingsLayout.vue';
import BaseSettingsHeader from '../components/BaseSettingsHeader.vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStoreGetters, useStore } from 'dashboard/composables/store';
import { useAccount } from 'dashboard/composables/useAccount';
import ContactAPI from 'dashboard/api/contacts'
import PopupAddBot from 'dashboard/routes/dashboard/conversation/contact/PopupAddBot.vue'

export default {
  components: {
    SettingsLayout,
    BaseSettingsHeader,
    Thumbnail,
    PopupAddBot
  },
  data() {
    return {
      showDeletePopup: false,
      selectedBot: {},
      botsList: [],
      showAddBotModal: false,
    };
  },
  setup() {
    const { accountId } = useAccount();
    const { isAdmin } = useAdmin();

    return {
      accountId,
      isAdmin
    };
  },
  mounted() {
    this.getBotsList()
  },
  computed: {
    deleteConfirmText() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.YES')} ${this.selectedBot.name}`
    },
    deleteRejectText() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.NO')} ${this.selectedBot.name}`
    },
    confirmDeleteMessage() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.MESSAGE')} ${this.selectedBot.name}?`
    },
    confirmPlaceHolderText() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.PLACE_HOLDER', {
        botName: this.selectedBot.name,
      })}`
    },
    isResponseBot() {
      return (bot) => bot.botType === 'response'
    }
  },
  methods: {
    async getBotsList() {
      const response = await ContactAPI.getListBot(this.accountId)
      this.botsList = response.data
    },
    openDelete(bot) {
      this.showDeletePopup = true;
      this.selectedBot = bot;
    },
    confirmDeletion() {
      this.deleteBot(this.selectedBot);
      this.closeDelete();
    },
    closeDelete() {
      this.showDeletePopup = false;
    },
    async deleteBot() {
      const res = await ContactAPI.deleteBot(this.selectedBot.id)
      this.botsList = this.botsList.filter(bot => bot.id !== this.selectedBot.id)
    },
    showPopupEditBot(bot) {
      this.showAddBotModal = true
      this.$refs.popupAddBotRef.setValue(bot)
    },
    showPopupAddBot() {
      this.showAddBotModal = true
      this.$refs.popupAddBotRef.setValue()
    },
    toggleCancelModal() {
      this.showAddBotModal = false
    },
    async updateAddbot(newBot) {
      this.showAddBotModal = false
      this.getBotsList()
    }
  }
}
// const getters = useStoreGetters();
// const store = useStore();
// const { t } = useI18n();

// const showDeletePopup = ref(false);
// const selectedInbox = ref({});
// const { accountId } = useAccount()

// const botsList = computed(() => getters['inboxes/getInboxes'].value);
// const uiFlags = computed(() => getters['inboxes/getUIFlags'].value);

// const deleteConfirmText = computed(
//   () => `${t('INBOX_MGMT.DELETE.CONFIRM.YES')} ${selectedInbox.value.name}`
// );

// const deleteRejectText = computed(
//   () => `${t('INBOX_MGMT.DELETE.CONFIRM.NO')} ${selectedInbox.value.name}`
// );

// const confirmDeleteMessage = computed(
//   () => `${t('INBOX_MGMT.DELETE.CONFIRM.MESSAGE')} ${selectedInbox.value.name}?`
// );
// const confirmPlaceHolderText = computed(
//   () =>
//     `${t('INBOX_MGMT.DELETE.CONFIRM.PLACE_HOLDER', {
//       inboxName: selectedInbox.value.name,
//     })}`
// );

// const deleteInbox = async ({ id }) => {
//   try {
//     await store.dispatch('inboxes/delete', id);
//     await ZaloAPI.deleteInbox(accountId.value, id);
//     useAlert(t('INBOX_MGMT.DELETE.API.SUCCESS_MESSAGE'));
//   } catch (error) {
//     useAlert(t('INBOX_MGMT.DELETE.API.ERROR_MESSAGE'));
//   }
// };

// const confirmDeletion = () => {
//   deleteInbox(selectedInbox.value);
//   closeDelete();
// };
// const openDelete = inbox => {
//   showDeletePopup.value = true;
//   selectedInbox.value = inbox;
// };
</script>

<template>
  <SettingsLayout :no-records-found="!botsList.length" no-records-message="There are no bots">
    <template #header>
      <BaseSettingsHeader title="Bots" feature-name="Bots" description="">
        <template #actions>
          <woot-button v-if="isAdmin" icon="add-circle" @click="showPopupAddBot">
            Thêm bot
          </woot-button>
        </template>
      </BaseSettingsHeader>
    </template>
    <template #body>
      <table class="min-w-full overflow-x-auto divide-y divide-slate-75 dark:divide-slate-700">
        <tbody class="divide-y divide-slate-25 dark:divide-slate-800 flex-1 text-slate-700 dark:text-slate-100">
          <tr v-for="bot in botsList" :key="bot.id">
            <td class="py-4 ltr:pr-4 rtl:pl-4">
              <div class="flex items-center flex-row gap-4">
                <div>
                  <div class="block font-medium text-base capitalize">
                    {{ bot.name }}
                  </div>
                  <div>
                    {{ bot.path }}
                  </div>
                </div>
              </div>
            </td>
            <td class="py-4">
              <div class="flex gap-1 justify-end">
                <!-- <router-link :to="{
                  name: 'settings_inbox_show',
                  params: { inboxId: inbox.id },
                }">
                  <woot-button v-if="isAdmin" v-tooltip.top="$t('INBOX_MGMT.SETTINGS')" variant="smooth" size="tiny"
                    icon="settings" color-scheme="secondary" class-names="grey-btn" />
                </router-link> -->
                <router-link :to="{ name: 'training_bot' }">
                  <woot-button
                    v-if="isAdmin && isResponseBot(bot)"
                    v-tooltip.top="'Train bot'"
                    variant="smooth"
                    color-scheme="secondary"
                    size="tiny"
                    class-names="grey-btn"
                    icon="document"
                  />
                </router-link>
                <woot-button
                  v-if="isAdmin"
                  v-tooltip.top="'Sửa'"
                  variant="smooth"
                  size="tiny"
                  class-names="grey-btn"
                  icon="edit"
                  @click="showPopupEditBot(bot)"
                />
                <woot-button
                  v-if="isAdmin"
                  v-tooltip.top="$t('INBOX_MGMT.DELETE.BUTTON_TEXT')"
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  class-names="grey-btn"
                  icon="dismiss-circle"
                  @click="openDelete(bot)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <PopupAddBot
      ref="popupAddBotRef"
      :show="showAddBotModal"
      @cancel="toggleCancelModal"
      @updateAddbot="updateAddbot"
    />
    <woot-confirm-delete-modal
      v-if="showDeletePopup"
      v-model:show="showDeletePopup"
      :title="$t('INBOX_MGMT.DELETE.CONFIRM.TITLE')"
      :message="confirmDeleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
      :confirm-value="selectedBot.name"
      :confirm-place-holder-text="confirmPlaceHolderText"
      @on-confirm="confirmDeletion"
      @on-close="closeDelete"
    />
  </SettingsLayout>
</template>
