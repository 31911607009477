<script>
export default {
  name: 'QnaTrainingBot',
  data() {
    return {
      qnas: []
    }
  },
  methods: {
    addQna() {
      this.qnas.push({ question: '', answer: '' });
    },
    deleteAll() {
      this.qnas = [];
    },
    deleteQna(index) {
      this.qnas.splice(index, 1);
    }
  }
}
</script>

<template>
  <div>
    <div class="flex justify-end gap-3 items-center">
      <woot-button
        variant="link"
        color-scheme="alert"
        @click="deleteAll"
      >
        Delete all
      </woot-button>
      <woot-button
        v-tooltip.top="'Add Q&A'"
        variant="smooth"
        size="tiny"
        color-scheme="secondary"
        class-names="grey-btn"
        icon="add"
        @click="addQna"
      />
    </div>
    <div
      class=" bg-white border border-solid rounded-md dark:bg-slate-800 border-slate-50 dark:border-slate-700/50 p-4 mt-4"
      v-for="(qna, index) in qnas"
      :key="index"
    >
      <div class="flex justify-end w-full">
        <woot-button
          v-tooltip.top="'Delete'"
          variant="smooth"
          size="tiny"
          color-scheme="alert"
          class-names="grey-btn"
          icon="delete"
          @click="deleteQna(index)"
        />
      </div>
      <div class="relative flex flex-col gap-1">
        <label
          :for="index"
          class="mb-0.5 text-sm font-medium text-gray-900 dark:text-gray-50"
        >
          Question
        </label>
        <textarea v-model="qna.question" />
      </div>
      <div class="relative flex flex-col gap-1">
        <label
          :for="index"
          class="mb-0.5 text-sm font-medium text-gray-900 dark:text-gray-50"
        >
          Answer
        </label>
        <textarea class="h-28" v-model="qna.answer" />
      </div>
    </div>
  </div>
</template>
