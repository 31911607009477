import { instanceIntegration } from '../api/instance/instanceIntegration';

class ZaloAPI {
  loginCallback(params) {
    return instanceIntegration.get(`/zalo/loginCallback`, { params: params });
  }
  updateZaloOa(id, body) {
    return instanceIntegration.put(`/zalo/${id}`, body)
  }

  deleteInbox(account_id, inbox_id) {
    return instanceIntegration.delete(`/zalo?account_id=${account_id}&inbox_id=${inbox_id}`)
  }

  getZaloInbox(account_id, inbox_id) {
    return instanceIntegration.get(`/zalo?account_id=${account_id}&inbox_id=${inbox_id}`)
  }
}

export default new ZaloAPI();
