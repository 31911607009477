<script>
import { getInboxClassByType } from 'dashboard/helper/inbox';
import { ZALO_CALLBACK } from 'dashboard/routes/dashboard/conversation/constants';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';

export default {
  props: {
    inbox: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    computedInboxClass() {
      let { phone_number: phoneNumber, channel_type: type, webhook_url } = this.inbox;
      if (type === INBOX_TYPES.API && webhook_url === ZALO_CALLBACK) {
        type = INBOX_TYPES.ZALO
      }
      const classByType = getInboxClassByType(type, phoneNumber);
      return classByType;
    },
  },
};
</script>

<template>
  <div
    class="inbox--name inline-flex items-center py-0.5 px-0 leading-3 whitespace-nowrap bg-none text-slate-600 dark:text-slate-500 text-xs my-0 mx-2.5"
  >
    <fluent-icon
      class="mr-0.5 rtl:ml-0.5 rtl:mr-0"
      :icon="computedInboxClass"
      size="12"
    />
    {{ inbox.name }}
  </div>
</template>
