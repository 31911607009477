<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useUISettings } from 'dashboard/composables/useUISettings';
import { useMapGetter } from 'dashboard/composables/store.js';
import wootConstants from 'dashboard/constants/globals';
import { FEATURE_FLAGS } from 'dashboard/featureFlags';

import ConversationBasicFilter from './widgets/conversation/ConversationBasicFilter.vue';
import ContactAPI from '../api/contacts';
import IntegrationCon from '../routes/dashboard/conversation/contact/IntegrationCon.vue';
import SwitchLayout from 'dashboard/routes/dashboard/conversation/search/SwitchLayout.vue';
import NextButton from 'dashboard/components-next/button/Button.vue';

const props = defineProps({
  pageTitle: {
    type: String,
    required: true,
  },
  pageLink: {
    type: String,
    required: false
  },
  hasAppliedFilters: {
    type: Boolean,
    required: true,
  },
  hasActiveFolders: {
    type: Boolean,
    required: true,
  },
  activeStatus: {
    type: String,
    required: true,
  },
  isOnExpandedLayout: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits([
  'addFolders',
  'deleteFolders',
  'resetFilters',
  'basicFilterChange',
  'filtersModal',
]);

const { uiSettings, updateUISettings } = useUISettings();

const activeInboxId = useMapGetter('getSelectedInbox');
const currentUser = useMapGetter('getCurrentUser');
const currentAccountId = useMapGetter('getCurrentAccountId');
const isFeatureEnabledonAccount = useMapGetter(
  'accounts/isFeatureEnabledonAccount'
);

const onBasicFilterChange = (value, type) => {
  emit('basicFilterChange', value, type);
};

const hasAppliedFiltersOrActiveFolders = computed(() => {
  return props.hasAppliedFilters || props.hasActiveFolders;
});

const showV4View = computed(() => {
  return isFeatureEnabledonAccount.value(
    currentAccountId.value,
    FEATURE_FLAGS.CHATWOOT_V4
  );
});

const toggleConversationLayout = () => {
  const { LAYOUT_TYPES } = wootConstants;
  const {
    conversation_display_type: conversationDisplayType = LAYOUT_TYPES.CONDENSED,
  } = uiSettings.value;
  const newViewType =
    conversationDisplayType === LAYOUT_TYPES.CONDENSED
      ? LAYOUT_TYPES.EXPANDED
      : LAYOUT_TYPES.CONDENSED;
  updateUISettings({
    conversation_display_type: newViewType,
    previously_used_conversation_display_type: newViewType,
  });
};

const showIntegrationModal = ref(false);
const integrated = ref(false);
const integrationInfo = ref({});
const appConfig = ref({});

watch(
  activeInboxId,
  () => {
    if(!activeInboxId.value) return;
    getIntegrationInfo()
  }
)

onMounted(() => {
  getAppConfig()
  if(activeInboxId.value) {
    getIntegrationInfo()
  }
})

const toggleIntegrationModal = () => {
  showIntegrationModal.value = !showIntegrationModal.value;
}

const getAppConfig = async () => {
  const response = await ContactAPI.getAppConfig()
  appConfig.value = response.data
}

const updateIntegration = async (botId) => {
  try {
    const data = {
      inbox_id: activeInboxId.value,
      account_id: currentUser.value.account_id,
      active_bot: true,
      bot_id: botId,
      api_access_token: currentUser.value.access_token
    }
    const response = await ContactAPI.activeIntegration(data)
    integrationInfo.value = response.data
    integrated.value = true
    showIntegrationModal.value = false
  } catch (error) {
    console.log('error', error);
  }
}

// const updateIntegration = async (active_bot) => {
//   try {
//     const data = {
//       inbox_id: activeInboxId.value,
//       account_id: currentUser.value.account_id,
//       active_bot: active_bot,
//       api_access_token: currentUser.value.access_token
//     }
//     const response = await ContactAPI.activeIntegration(data)
//     integrationInfo.value = response.data
//     integrated.value = active_bot
//   } catch (error) {
//     console.log('error', error);
//   }
// }

const getIntegrationInfo = async () => {
  integrated.value = false
  const response = await ContactAPI.getIntegration(activeInboxId.value, currentUser.value.account_id)
  integrationInfo.value = response.data
  if (response.data && response.data.active_bot) {
    integrated.value = true
  }
}

const deleteIntegration = async () => {
  const response = await ContactAPI.deleteIntegration(integrationInfo.value.id)
  if (response) {
    integrated.value = false
    integrationInfo.value = {}
  }
  showIntegrationModal.value = false
}
</script>

<template>
  <div
    class="flex items-center justify-between gap-2 px-4 pb-0"
    :class="{
      'pb-3 border-b border-n-strong': hasAppliedFiltersOrActiveFolders,
      'pt-2.5': showV4View,
    }"
  >
    <div class="flex items-center justify-center min-w-0">
      <a
        v-if="pageLink"
        class="text-lg font-medium truncate text-primary-600"
        :href="pageLink"
        target="_blank"
      >
        {{ pageTitle }}
      </a>
      <h1
        v-else
        class="text-lg font-medium truncate text-n-slate-12"
        :title="pageTitle"
      >
        {{ pageTitle }}
      </h1>
      <span
        v-if="!hasAppliedFiltersOrActiveFolders"
        class="px-2 py-1 my-0.5 mx-1 rounded-md capitalize bg-n-slate-3 text-xxs text-n-slate-12 shrink-0"
      >
        {{ $t(`CHAT_LIST.CHAT_STATUS_FILTER_ITEMS.${activeStatus}.TEXT`) }}
      </span>
      <!-- <woot-switch
        size="small"
        class="mx-1 mt-px mb-0"
        :model-value="integrated"
        @input="updateIntegration"
        v-if="activeInboxId && appConfig?.integrated"
        title="Tích hợp"
      /> -->
      <woot-button
        v-if="activeInboxId && appConfig?.integrated"
        size="small"
        @click="toggleIntegrationModal"
      >
        {{ integrated? 'Sửa tích hợp': 'Tích hợp' }}
      </woot-button>
    </div>
    <div class="flex items-center gap-1">
      <template v-if="hasAppliedFilters && !hasActiveFolders">
        <div class="relative">
          <NextButton
            v-tooltip.top-end="$t('FILTER.CUSTOM_VIEWS.ADD.SAVE_BUTTON')"
            icon="i-lucide-save"
            slate
            xs
            faded
            @click="emit('addFolders')"
          />
          <div
            id="saveFilterTeleportTarget"
            class="absolute z-40 mt-2"
            :class="{ 'ltr:right-0 rtl:left-0': isOnExpandedLayout }"
          />
        </div>
        <NextButton
          v-tooltip.top-end="$t('FILTER.CLEAR_BUTTON_LABEL')"
          icon="i-lucide-circle-x"
          ruby
          faded
          xs
          @click="emit('resetFilters')"
        />
      </template>
      <template v-if="hasActiveFolders">
        <div class="relative">
          <NextButton
            id="toggleConversationFilterButton"
            v-tooltip.top-end="$t('FILTER.CUSTOM_VIEWS.EDIT.EDIT_BUTTON')"
            icon="i-lucide-pen-line"
            slate
            xs
            faded
            @click="emit('filtersModal')"
          />
          <div
            id="conversationFilterTeleportTarget"
            class="absolute z-40 mt-2"
            :class="{ 'ltr:right-0 rtl:left-0': isOnExpandedLayout }"
          />
        </div>
        <NextButton
          id="toggleConversationFilterButton"
          v-tooltip.top-end="$t('FILTER.CUSTOM_VIEWS.DELETE.DELETE_BUTTON')"
          icon="i-lucide-trash-2"
          ruby
          xs
          faded
          @click="emit('deleteFolders')"
        />
      </template>
      <div v-else class="relative">
        <NextButton
          id="toggleConversationFilterButton"
          v-tooltip.right="$t('FILTER.TOOLTIP_LABEL')"
          icon="i-lucide-list-filter"
          slate
          xs
          faded
          @click="emit('filtersModal')"
        />
        <div
          id="conversationFilterTeleportTarget"
          class="absolute z-40 mt-2"
          :class="{ 'ltr:right-0 rtl:left-0': isOnExpandedLayout }"
        />
      </div>
      <ConversationBasicFilter
        v-if="!hasAppliedFiltersOrActiveFolders"
        :is-on-expanded-layout="isOnExpandedLayout"
        @change-filter="onBasicFilterChange"
      />
    </div>
    <SwitchLayout
      v-if="showV4View"
      :is-on-expanded-layout="isOnExpandedLayout"
      @toggle="toggleConversationLayout"
    />
    <IntegrationCon
      :show="showIntegrationModal"
      :integrated="integrated"
      :integrationInfo="integrationInfo"
      @cancel="toggleIntegrationModal"
      @updateIntegration="updateIntegration"
      @deleteIntegration="deleteIntegration"
    />
  </div>
</template>
