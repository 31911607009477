<script>
import { mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { useAlert } from 'dashboard/composables';
import { useAccount } from 'dashboard/composables/useAccount';
import { required } from '@vuelidate/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader.vue';
import FacebookAPI from 'dashboard/api/facebook';
import { ZALO_CALLBACK } from 'dashboard/routes/dashboard/conversation/constants';

export default {
  components: {
    PageHeader,
  },
  setup() {
    const { accountId } = useAccount();

    return {
      accountId,
      v$: useVuelidate(),
    };
  },
  validations: {
    selectedPage: {
      isEmpty() {
        return this.selectedPage !== null && !!this.selectedPage.name;
      },
    },
  },
  data() {
    return {
      selectedPage: {},
      fbPageList: [],
      connectUrl: `${process.env.INTEGRATION_BOT_URL}/facebook/connect?account_id=${this.accountId}&type=create`,
      loading: false,
    }
  },
  mounted() {
    if (this.isLoginSuccess) {
      this.getListFbPage()
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser'
    }),
    isLogin() {
      return !!this.$route.query.login
    },
    isLoginSuccess() {
      return this.$route.query.login === 'success'
    }
  },
  validations: {
    botToken: { required },
  },
  methods: {
    async fbLogin() {
      console.log(this.currentUser)
      await FacebookAPI.login({ account_id: this.accountId, type: 'create' })
    },
    async createInbox() {
      if (this.loading) return
      this.loading = true
      try {
        const res = await FacebookAPI.createInbox({
          account_id: this.accountId,
          page_id: this.selectedPage.page_id,
          api_access_token: this.currentUser.access_token
        })
        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: res.data.id,
          },
        });
      } catch (err) {
        useAlert('Đã xảy ra lỗi')
      }
      this.loading = false
    },
    async getListFbPage() {
      const res = await FacebookAPI.getListFbPage({ account_id: this.accountId })
      this.fbPageList = res.data
    }
  },
};
</script>

<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0">
    <div v-if="!isLogin">
      <PageHeader header-title="Facebook Channel"
        header-content="Integrate with Zalo channel and start supporting your customers." />
      <div>
        <a :href="connectUrl">
          <img class="w-auto h-10" src="~dashboard/assets/images/channels/facebook_login.png" alt="Facebook-logo" />
        </a>
      </div>
    </div>
    <div v-else-if="isLoginSuccess">
      <form class="flex flex-wrap mx-0" @submit.prevent="createInbox()">
        <div class="w-full">
          <PageHeader :header-title="$t('INBOX_MGMT.ADD.DETAILS.TITLE')" />
        </div>
        <div class="w-3/5">
          <div class="w-full">
            <div class="input-wrap">
              {{ $t('INBOX_MGMT.ADD.FB.CHOOSE_PAGE') }}
              <multiselect v-model="selectedPage" close-on-select allow-empty :options="fbPageList" track-by="page_id"
                label="name" :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                :placeholder="$t('INBOX_MGMT.ADD.FB.PICK_A_VALUE')" selected-label />
              <span class="message">
                {{ $t('INBOX_MGMT.ADD.FB.CHOOSE_PLACEHOLDER') }}
              </span>
            </div>
          </div>
          <div class="w-full text-right">
            <input type="submit" value="Create Inbox" class="button" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
