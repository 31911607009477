<script>
import { useVuelidate } from '@vuelidate/core';
import { url } from '@vuelidate/validators';


export default {
  name: 'WebsiteTrainingBot',
  data() {
    return {
      v$: useVuelidate(),
      crawlLink: '',
      sitemapLink: '',
      includedLinks: [],
      link: ''
    }
  },
  validations() {
    return {
      crawlLink: { url },
      sitemapLink: { url },
      link: { url },
    }
  },
  methods: {
    onAddLink() {
      this.includedLinks.push(this.link);
    }
  }
}
</script>

<template>
  <div>
    <div class="border-b border-solid border-slate-50 dark:border-slate-700/30 mb-4 pb-4">
      <div class="flex gap-2">
        <woot-input
          v-model="crawlLink"
          :class="{ error: v$.crawlLink.$error }"
          class="w-full label-name--input"
          label="Crawl"
          placeholder="https://www.example.com"
          :error="v$.crawlLink.$error? 'Đường dẫn không hợp lệ': ''"
          data-testid="bot-name"
          @input="v$.crawlLink.$touch"
          @blur="v$.crawlLink.$touch"
        />
        <woot-button class="mt-7">Fetch links</woot-button>
      </div>
      <p class="pb-1 text-sm not-italic text-slate-600 dark:text-slate-400">
        This will crawl all the links starting with the URL (not including files on the website).
      </p>
    </div>
    <div class="border-b border-solid border-slate-50 dark:border-slate-700/30 my-4 py-4">
      <div class="flex gap-2">
        <woot-input
          v-model="sitemapLink"
          :class="{ error: v$.sitemapLink.$error }"
          class="w-full label-name--input"
          label="Submit Sitemap"
          placeholder="https://www.example.com/sitemap.xml"
          :error="v$.sitemapLink.$error? 'Đường dẫn không hợp lệ': ''"
          data-testid="bot-name"
          @input="v$.sitemapLink.$touch"
          @blur="v$.sitemapLink.$touch"
        />
        <woot-button class="mt-7">Load sitemap</woot-button>
      </div>
    </div>
    <div class="border-b border-solid border-slate-50 dark:border-slate-700/30 my-4 py-4">
      <div class="flex gap-2">
        <woot-input
          v-model="link"
          :class="{ error: v$.link.$error }"
          class="w-full label-name--input"
          label="Included Links"
          placeholder="https://www.example.com"
          :error="v$.link.$error? 'Đường dẫn không hợp lệ': ''"
          data-testid="bot-name"
          @input="v$.link.$touch"
          @blur="v$.link.$touch"
        />
        <woot-button class="mt-7" @click="onAddLink">Add link</woot-button>
      </div>
      <div v-for="includedLink in includedLinks" :key="includedLink">
        {{ includedLink }}
      </div>
    </div>
  </div>
</template>
