<script>
import TextArea from 'dashboard/components-next/textarea/TextArea.vue';

export default {
  name: 'TextTrainingBot',
  components: {
    TextArea
  },
  data() {
    return {
      text: ''
    }
  },
}
</script>

<template>
  <div>
    <textarea
      v-model="text"
      class="h-72"
      placeholder="Enter text..."
    />
  </div>
</template>
